@import url('https://fonts.googleapis.com/css2?family=Italiana&family=Roboto:wght@300;400;700&display=swap');

.whole-page{
    height:100vh;
}

.landing-image{
    height:300px;
    border-radius:20px;
}

.hero-div{
    display:flex;
    margin-left:5%;
    margin-right:5%;
    margin-top:2%;
}

.hero-header{
font-size:48px;
font-family: 'Italiana', serif;
margin:0px;
color:var(--blue)
}

.hero-text{
font-size:24px;
width:750px;
line-height:5.5vh;
}

.mission-div{
    color: var(--blue);
    background-color:var(--pink);
    text-align: center;
    font-size:32px;
    margin-bottom:10px;
    margin-top:10px;
}

.landing-nav{
    display:flex;
    justify-content: space-around;
    margin-top:0px;
}
.charity-div{
    display:flex;
    flex-direction:column;
    background-color:var(--blue);
    width:600px;
    margin-left:20px;
    margin-right:20px;
    color:var(--pink);
    font-size: 24px;
    text-align: center;
    padding:30px;
    margin-bottom:10px;
    border-radius:20px;
    align-items:center;

}
.donations-div{
    text-align: center;
    display:flex;
    flex-direction:column;
    background-color:var(--blue);
    width:600px;
    margin-left:20px;
    margin-right:20px;
    color:var(--pink);
    font-size: 24px;
    padding:30px;
    margin-bottom:10px;
    border-radius:20px;
    align-items:center;
}

button.donations-button{
    width:350px;
    height:50px;
    font-size:20px;
    margin-top:15px;
    border-radius:20px;
    border:3px solid white;
    color:var(--blue);
    background-color:var(--pink);
    font-weight:bold;
}

.charity-button{
 width:250px;
 height:50px;
 font-size:20px;
 margin-top:15px;
 border-radius:20px;
 border:3px solid white;
 color:var(--blue);
 background-color:var(--pink);
 font-weight:bold;
}


.login-link:hover{
    color:white;
    text-decoration:underline;
    cursor:pointer;
}

.login-link{
    font-size:20px;
}