/* .single-charity-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #FFC0CB;
    font-family: Arial, sans-serif;
  } */
  
  .single-charity-page h1 {
    margin-bottom: 1rem;
    color: #05667B;
    font-size: 2rem;
    border-top:3px solid var(--pink);
    border-bottom:3px solid var(--pink);
    text-align:center;
  }

  .single-charity-page h5 {
    color: #05667B;
    text-align:center;
    font-size:18px;
    margin:0px;
    margin-top:10px;
    margin-bottom:10px;
  }
  
  .single-charity-page p {
    max-width: 800px;
    margin-bottom: 1rem;
    color: #05667B;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .single-charity-page img {
    max-width: 50%;
    height: auto;
    margin-bottom: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin:20px;
  }
  
  .donate-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #05667B;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .donate-button:hover {
    background-color: #045569;
  }

  .main-content{
    display:flex;
    background-color:var(--pink);
  }
  

  .text-button{
    display:flex;
    flex-direction:column;
    align-items:space-around;
    justify-content:space-around;
    font-weight:bold;
    line-height:30px;
    margin:20px;
    border:1px solid var(--blue);
  }

  .text-button button{
    width:300px;
  }