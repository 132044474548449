.donation-page{
    text-align:center;
    display:flex;
    margin-top:20px;
    justify-content:center;
}


#form-image{
 height:560px;
 border-radius:20px;
}



#mpesa-logo{
width:130px;
height:80px;
margin:0px;
}

.paypal-logo{
    width:120px;
    height:30px;
    margin-top:15px;
    align-items:center;
    
}

.card-svg{
width:50px;
margin:0px;
height:40px;
}

.card-pay-div{
    display: flex;
    align-items:center;
    color:var(--blue);
    font-size:24px;
    font-weight:bold;
  

}

.main-header{
color:var(--blue);
font-size:32px;
text-align:center;


}

.sub-header{
font-size:24px;
color:var(--blue)
}

.form-container-mpesa{
    border:3px solid var(--green);
    width:900px;
    border-radius:20px;
    height:550px;
    margin-left:0px;
}

.pay-methods{
    display:flex;
    justify-content:center;
    margin:0px;
}

.pay-form-mpesa{
    border:3px solid var(--green);
    margin:0px 20px 0px 20px;
    color:var(--green);
    border-radius:20px;
    height:400px;
    /* background-color:orange; */
}

.pay-form-mpesa input{
    text-align:center;
    border:1px solid var(--grey);
    border-radius:5px;
    height:30px;
}

.pay-form input[type="text"] {
width:350px;
height:30px;
}

#prompt-button{
    background-color:green;
    color:white;
    border:0px;
    width:120px;
    height:40px;
    font-size:16px;
    border-radius:20px;
    font-weight:bold;
    margin-bottom:10px;
    margin-left:auto;
    margin-right:auto;
}



.contacts{
display: flex;
justify-content:space-around;
width:800px;
margin-left: auto;
margin-right: auto;
}

/* .payer-details{
display: flex;
flex-wrap:wrap;
justify-content:space-around;
width:800px;
background-color:red;
margin-left: auto;
margin-right: auto;} */

.paybill-div{
    display:flex;
    flex-direction:column;
    border:1px solid black;
    border-radius:10px;
    width:240px;
    margin-top:20px;
    color:black
    
}

.send-money{
    display:flex;
    flex-direction:column;
    border:1px solid black;
    border-radius:10px;
    width:240px;
    margin-top:20px;
    color:black

}

.send-money-details{
    background-color:var(--green);
    padding-top:10px;
    padding-bottom:10px;
    justify-content:space-around;
    align-items:space-around;
    color:white;
    margin:10px;
    border-radius:5px;
    height:80%;

}

.send-money-details div{
    margin-top:30px;
    margin-bottom:5px;
    border:1px solid black;
    background-color:white;
    width:200px;
    margin-left:auto;
    margin-right:auto;
    height:40px;
    color:black;
    font-weight:bold;
    border-radius:5px;
    /* border-radius:; */

}
#mpesa-number{
font-size:20px;
}
.paybill-details{
    background-color:var(--green);
    padding-top:10px;
    padding-bottom:10px;
    justify-content:space-around;
    align-items:space-around;
    color:white;
    margin-left:auto;
    margin-right:auto;
    margin:10px;
    border-radius:5px;
    width:220px;
}

.paybill-details div{
    margin-top:10px;
    margin-bottom:5px;
    border:1px solid black;
    background-color:white;
    width:180px;
    margin-left:auto;
    margin-right:auto;
    color:black;
    font-weight:bold;
    border-radius:5px;
    /* border-radius:; */
 
}

.paybill-details  div{
 height:40px;
}

.payer-details input{
margin-top:20px;
    }

.additional-options{
display: flex;
height:20px;
justify-content:space-around;
width:800px;
margin-left: auto;
margin-right: auto;
/* background-color: brown; */
font-size:14px;

}
.additional-options input{
margin:0px;
    
    }

.prompt{
    display:flex;
    flex-direction:column;
    border:1px solid black;
    border-radius:10px;
    width:220px;
    margin-top:20px;
    color:black

}

.prompt-details{
    background-color:var(--green);
    padding-top:10px;
    justify-content:space-around;
    align-items:space-around;
    color:white;
    margin:10px;
    border-radius:5px;
    height:50%;

}

.prompt-details input{
    border:1px solid black;
    background-color:white;
    width:170px;
    margin-left:auto;
    margin-right:auto;
    color:black;
    border-radius:5px;
    margin-bottom:10px;
    margin-top:10px;
}

.pay-options{
    display:flex;
    justify-content:space-around;
    color:var(--green);
}

.bill-mpesa{
    display:flex;
}


#options-header{
 padding-top:20px;
}

form h3{
    padding-top:10px;
    color:black
}


#send-number{
    padding-top:10px;
    margin-top:10px;
}


.additional-options div{
    margin-top:5px;
}