.charity-beneficiaries-management {
  width: 80%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  text-align:center;
}

.charity-beneficiaries-management h1 {
  color: #05667B;
  text-align: center;
  margin-bottom: 1.5rem;
}

.charity-beneficiaries-management h2 {
  color: #05667B;
  margin-bottom: 1rem;
}

.charity-beneficiaries-management form {
  display: flex;
  flex-direction:row;
  align-items:space-around;
  justify-content:space-around;
  background-color:var(--pink);
  border-radius: 5px;
  height:30px;
  width:45%;
  margin-left:auto;
  margin-right:auto;
}
.charity-beneficiaries-management button {
margin:0px;
width:200px;
height:25px;
font-size:16px;
display:flex;
align-items:center;
justify-content:center;
}

.charity-beneficiaries-management ul {
  list-style-type: none;
  padding: 0;
}

.charity-beneficiaries-management li {
  border:5px solid var(--pink);
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.charity-beneficiaries-management li button {
  background-color:var(--blue);
  width:60px;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  margin:0px;
}

.charity-beneficiaries-management li button:hover {
  background-color: #f5a8b8;
}

.name{
margin:0px;
height:30px;
color:var(--blue);
display:flex;
align-items:center;
font-weight: bolder;
}

.add-beneficiary{
  display:flex;
  align-items:center;
}


.beneficiary{
  display:flex;
  flex-direction:column;
  width:400px;
}

.buttons{
  display:flex;
}

.beneficiary-container{
  display:flex;
  justify-content:space-around;
  flex-wrap:wrap;

}

#inventory-form{
  display:flex;
  background-color:var(--pink);
  align-items: center;
  margin-top:40px;
  justify-content:space-around;
  height:50px;
  width:400px;
}

#inventory-form h5{
color:var(--blue);
}

#inventory-form input{
width:100px;
text-align:center;
}


.beneficiary-name{
  color:var(--blue);
  font-size:32px;
  font-weight:bold;
}

.inventory{
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  align-items: space-around;
  width:200px;
  text-align:center;
font-weight:bold;
}

.inventory-titles{
display:flex;
justify-content:space-around;
align-items: space-around;
width:100%;
margin-bottom:10px;
}
.inventory-titles li{
margin:0px;
padding:0px;
border:none;
color:var(--pink);
  }

.inventory-data {
  display:flex;
  justify-content:space-around;
  align-items: space-around;
  width:100%;
  color:var(--blue);
  }
.inventory-data li{
  margin:0px;
  padding:0px;
  border:none;
}