.form-container {
    background-color: #fff;
    border: 1px solid #dddfe2;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 400px; /* increased width */
    max-width: 100%;
    margin: 0 auto; /* added margin to center horizontally */
  }
  
  .form-container h1 {
    color:var(--blue);
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  
  .form-container label {
    display: block;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 8px;
  }
  
  .form-container input[type="text"],
  .form-container input[type="password"] {
    background-color: #f0f2f5;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    padding: 12px 16px;
    width: 100%;
  }
  
  .form-container input[type="text"]:focus,
  .form-container input[type="password"]:focus {
    outline: none;
  }
  
  .form-container input[type="submit"] {
    background-color:var(--pink);
    border: none;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color:var(--blue);
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
    padding: 12px 16px;
    transition: background-color 0.2s ease;
    width: 100%;
  }
  
  .form-container input[type="submit"]:hover {
    background-color:white;
    border:1px solid var(--pink);
  }
  
  .form-container button {
    background-color: transparent;
    border: none;
    color: var(--blue);
    cursor: pointer;
    font-size: 14px;
    margin-top: 8px;
    text-align: left;
  }
  
  .form-container button:hover {
    text-decoration: underline;
  }

  label{
    color:var(--blue)
  }
  