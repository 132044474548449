h2#charity-page-h2{
  color:var(--blue);
  text-align:center;
  margin:10px;
  font-size:40px;
}

.content-container{
  width:90%;
  border:5px solid var(--pink);
  margin-left:auto;
  margin-right:auto;
  border-radius:20px;
  display:flex;
  flex-direction:column;
}

.content-container h3{
  color:var(--blue);
  border:3px solid var(--blue);
  width:300px;
  margin-left:auto;
  margin-right:auto;
  background-color:var(--pink);
}

.donors{
  display:flex;
  justify-content:space-around;
}

.total-amount{
  text-align:center;
}

.named{
  display:flex;
  flex-direction:column;
  width:40%;
  text-align:center;
  border:5px solid var(--blue);
  margin-top:20px;
  padding-bottom:20px;
  font-weight:bold;
  color:var(--blue);
}

.anonymous{
  display:flex;
  flex-direction:column;
  width:40%;
  text-align:center;
  border:5px solid var(--blue);
  margin-top:20px;
  padding-bottom:20px;
  font-weight:bold;
  color:var(--blue);

}

.single-donor{
  display:flex;
  justify-content:space-around;
  margin-top:10px;
  margin-bottom:10px;
}


#total-amount{
  background-color:white;
  padding:10px;
  border-radius:10px
}